import React from 'react'
import BaseLayout from '../layouts/BaseLayout';

export default function fraction() {
    return (
        <BaseLayout>
            
        </BaseLayout>
    )
}
